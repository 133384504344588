import React from 'react';
import cn from 'classnames';

import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';
import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';

import baseStyles from '../tenantInvestorSet.scss';
import btnStyles from '@/ui/shared/components/Button/button.scss';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './learnMore.scss';

const LearnMore = () => (
  <div className={ styles.learnMoreContainer }>
    <div className={ styles.learnMoreDescription }>
      <div className={ baseStyles.sectionTitle }>Ready to learn more?</div>
      <div className={ baseStyles.sectionDescription }>
        Get started or schedule a demo with a product specialist who can answer
        any questions and show you the platform.
      </div>
    </div>

    <div className={ styles.learnMoreButtons }>
      <GetStartedButton className={ cn(btnStyles.secondary, baseStyles.secondaryBtn) } />
      <BookDemoButton className={ spacesStyles.mrn } />
    </div>
  </div>
);

export default LearnMore;
